import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Cookies from 'js-cookie';
import React, { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import DownloadIcon from '@mui/icons-material/Download';
import CardActions from '@mui/material/CardActions';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import ExcelJS from 'exceljs';
import { saveAs } from "file-saver";
import { useNavigate } from 'react-router-dom';
import { Color } from '../../../GlobalStyles';
import Service from '../../../services/httpService';
import CCService from '../../../services/httpServiceWithoutSuperAdmin';
import "../../pages/SupportTicket/TableStyling.css";
import Loader, { ActivityLoader, SmallPageLoader } from '../../reusables/Loader/Loader';
import ModalSt from "../College1x1StudentDetails/ModalSt";
// import XLSX from 'xlsx';
import { calculateEpochTimeInLocalTime, extractDateFromDateString } from '../../../services/specialCharReplace';
const CreateTable = (props) => {
  const service = new Service()
  const services = new CCService()
  const navigate = useNavigate();
  const { is_b2c } = props;
  console.log("CreateTable ~ is_b2c:", is_b2c)
  const [studentDetails, setStudentDetails] = useState([])
  const { batchName, college_data_name, process_id, sku_name, searched, isAnomalies, studentAnomaliesDetails } = props
  console.log("studentAnomaliesDetails", studentAnomaliesDetails);

  console.log("process_id in create table", process_id);
  console.log("sku_name in create table", sku_name);
  console.log("searched in create table", searched);
  const [openModal, setOpenModal] = useState(false)
  const tableTitles = ["Student Details", "Mentor Details", "Date and Time", "Role", "Status"];
  const [studentsDetailsClick, setStudentsDetailsClick] = useState({})
  const callModalToOpen = () => {
    console.log("Modal open fucntion called")
    setOpenModal(true);
  }
  const [warRoomDetailsSelected, setWarRoomDetailsSelected] = useState([])
  const [roleTypeData, setRoleTypeData] = useState([])
  const [mentorFeedbackDetails, setMentorFeedbackDetails] = useState([])
  const [studentFeedbackDetails, setStudentFeedbackDetails] = useState([])
  const [chatMessages, setChatMessages] = useState([])
  const [copyList, setCopyList] = useState(isAnomalies ? studentAnomaliesDetails : warRoomDetailsSelected)
  const currentDate = new Date();
  const [loading, setLoading] = useState(true);
  const [activityLoading, setActivityLoading] = useState(false);
  const [internetSpeedMentor, setInternetSpeedMentor] = useState([])
  const [internetSpeedStudent, setInternetSpeedStudent] = useState([])
  const [feedbackFields, setFeedbackFields] = useState({})
  const [mcqFeedbackFields, setMcqFeedbackFields] = useState({})
  const [studentFeedbackFields, setStudentFeedbackFields] = useState({})
  const [duration_name, setDuration_name] = useState()

  const [orderHistory, setOrderHistory] = useState([])
  // feedback fields
  const [mentorFeedbackFields, setMentorFeedbackFields] = useState([]);
  const [changedPairs, setChangedPairs] = useState([]);
  const [mentorFeedbackMCQs, setMentorFeedbackMCQs] = useState([]);
  // const [selectedOptions, setSelectedOptions] = useState(false)

  const [reconsiderRequest, setReconsiderRequest] = useState([])
  const [specialization_role_slots_jd, setSpecialization_role_slots_jd] = useState([])
  const [resumeRequest, setResumeRequest] = useState([])
  const [mentor_feedback_submitted_Request, setMentor_feedback_submitted_RequestRequest] = useState([])
  const [mentor_joined_Request, setMentor_joined_Request] = useState([])
  const [mentor_join_time_Request, setMentor_join_time_Request] = useState([])
  const [student_join_time_Request, setStudent_join_time_Request] = useState([])
  const [student_feedback_submitted_Request, setStudent_feedback_submitted_Request] = useState([])
  const [mark_absent_Request, setMark_absent_Request] = useState([])
  const [mentor_missed_Request, setMentor_missed_Request] = useState(false)
  const [order_flag_changes, setOrder_flag_changes] = useState([])
  const [allItems, setAllItems] = useState([])
  const [notification_ids, setNotification_ids] = useState([])
  const [internet_stats, setInternet_stats] = useState({})
  const [mentor_missed, setmentor_missed] = useState([])

  const [options, setOptions] = useState([
    { label: 'All', value: 'All', isSelected: false },
    { label: 'Not Scheduled', value: 'Not Scheduled', isSelected: false },
    { label: 'Initialized', value: 'Initialized', isSelected: false },
    { label: 'Scheduled', value: 'Scheduled', isSelected: false },
    { label: 'Done', value: 'Done', isSelected: false },
    { label: 'Mentor Absent', value: 'Mentor Absent', isSelected: false },
    { label: 'Mentor Awaited', value: 'Mentor Awaited', isSelected: false },
    { label: 'Awaited Mentor Feedback', value: 'Awaited Mentor Feedback', isSelected: false },
    { label: 'Received Mentor Feedback', value: 'Received Mentor Feedback', isSelected: false },
    { label: 'Student Absent', value: 'Student Absent', isSelected: false },
    { label: 'No Action Students', value: 'No Action Students', isSelected: false },
    { label: 'Ongoing', value: 'Ongoing', isSelected: false },
    { label: 'Expired Bookings', value: 'Expired Bookings', isSelected: false },
  ]);

  function calculateInstantaneousSpeed(bytesSent, bytesReceived, timestamp) {
    const timeIntervalSeconds = (Date.now() - timestamp) / 1000; // Convert milliseconds to seconds

    if (timeIntervalSeconds <= 0) {
      return 0; // Avoid division by zero or negative time intervals
    }

    const totalBytesTransferred = bytesSent + bytesReceived;
    const speedKbps = (totalBytesTransferred * 8) / (timeIntervalSeconds * 1000);

    return speedKbps;
  }
  function calculateSpeeds(internetSpeedMentor) {
    if (!internetSpeedMentor) return [];

    return internetSpeedMentor.map(data => {
      const publishSpeed = calculateInstantaneousSpeed(
        data?.publish?.bytesSent,
        data?.publish?.bytesReceived,
        data?.publish?.timestamp,
        Date.now()
      );

      const subscribeSpeed = calculateInstantaneousSpeed(
        data?.subscribe?.bytesSent,
        data?.subscribe?.bytesReceived,
        data?.subscribe?.timestamp,
        Date.now()
      );
      return { publishSpeed, subscribeSpeed };
    });
  }

  function calculateSpeedsStudent(internetSpeedStudent) {
    if (!internetSpeedStudent) return [];

    return internetSpeedStudent.map(data => {
      const publishSpeed = calculateInstantaneousSpeed(
        data?.publish?.bytesSent,
        data?.publish?.bytesReceived,
        data?.publish?.timestamp,
        Date.now()
      );

      const subscribeSpeed = calculateInstantaneousSpeed(
        data?.subscribe?.bytesSent,
        data?.subscribe?.bytesReceived,
        data?.subscribe?.timestamp,
        Date.now()
      );
      return { publishSpeed, subscribeSpeed };
    });
  }

  const [publishSeriesData, setPublishSeriesData] = useState([]);
  const [subscribeSeriesData, setSubscribeSeriesData] = useState([]);

  // for student
  const [publishSeriesDataStudent, setPublishSeriesDataStudent] = useState([]);
  const [subscribeSeriesDataStudent, setSubscribeSeriesDataStudent] = useState([]);

  useEffect(() => {
    console.log("Brother")
    const handleEffect = async () => {
      console.log("env", process.env.REACT_APP_RD_API_URL, process.env.REACT_APP_API_URL)
      if (Cookies.get('token') === null || Cookies.get('token') === undefined) {
        navigate('/');
      }
      if (isAnomalies) {
        console.log("isAnomalies", isAnomalies);
        let roleType = await roleTypeGetter()
        data_manupulator_roleType(roleType)
        console.log(studentAnomaliesDetails, studentAnomaliesDetails);

        setCopyList(studentAnomaliesDetails)
        setLoading(false)
      } else {
        let studentsDetails = await collegeDataGetter();
        let warRoomDetails = await warRoomDataGetter();

        let studentFeedback = await studentFeedbackGetter();

        let duration = await DurationGetter();
        data_manupulator_duration(duration);

        let roleType = await roleTypeGetter()
        let temp_data = studentsDetails.map(obj => {
          if (parseInt(obj.order_id) === parseInt(warRoomDetails[0].order_id)) {
            return obj;
          } else {
            const matchingWarRoomDetail = warRoomDetails.slice(1).find(param => parseInt(obj.order_id) === parseInt(param.order_id));
            if (matchingWarRoomDetail) {
              return {
                ...obj,
                act_type_id: matchingWarRoomDetail.act_type_id,
                act_type_name: matchingWarRoomDetail.act_type_name,
                bookable_status: matchingWarRoomDetail.bookable_status,
                duration: matchingWarRoomDetail.duration,
                end_time: matchingWarRoomDetail.end_time,
                is_absent: matchingWarRoomDetail.is_absent,
                mentor_feedback_submitted: matchingWarRoomDetail.mentor_feedback_submitted,
                mentor_id: matchingWarRoomDetail.mentor_id,
                sku_name: matchingWarRoomDetail.name,
                oop: matchingWarRoomDetail.oop,
                role_id: matchingWarRoomDetail.role_id,
                sku_id: matchingWarRoomDetail.sku_id,
                slot_date: matchingWarRoomDetail.slot_date,
                slot_name: matchingWarRoomDetail.slot_name,
                slots: matchingWarRoomDetail.slots,
                spe_id: matchingWarRoomDetail.spe_id,
                start_time: matchingWarRoomDetail.start_time,
                student_last_slot_cutoff: matchingWarRoomDetail.student_last_slot_cutoff,
                role_name: matchingWarRoomDetail.role_name,
                specialization_name: matchingWarRoomDetail.specialization_name,
                mentor_absent_count: matchingWarRoomDetail.mentor_absent_count
              };
            } else {
              return obj;
            }
          }
        });
        console.log("datas", { studentsDetails, warRoomDetails, studentFeedback, roleType })
        // Now temp_data contains the merged data with the first student unchanged

        console.log("temp data", temp_data);

        setWarRoomDetailsSelected(temp_data)

        setStudentFeedbackDetails(studentFeedback)
        //college getter
        setStudentDetails(temp_data)
        data_manupulator_roleType(roleType)
        let temp_time = new Date().getTime()

        if (temp_data.length !== 0 && searched) {
          const updatedOptions = options.map(option => ({
            ...option,
            isSelected: option.value === searched ? true : false
          }));
          setOptions(updatedOptions);

          console.log("inside if condition", searched);
          let filteredList;
          if (searched === "Student Absent") {
            filteredList = temp_data.filter((item) =>
              item.status === "Done" && item.is_absent === 1
            );
            console.log("Student Absent list", filteredList);
          } else if (searched === "Expired Bookings") {
            // {console.log("cut off time",new Date().getTime() + 19800000 , temp_data.map((item) => item.student_last_slot_cutoff))}
            filteredList = temp_data.filter((item) =>
              (item.status === "Initialized") && (temp_time > item.student_last_slot_cutoff)
            );
            console.log("Student Absent list", filteredList);
          } else if (searched === "Scheduled") {
            filteredList = temp_data.filter((item) =>
              item.status === "Scheduled"
            );
            console.log("Student Absent list", filteredList);
          } else if (searched === "Initialized") {
            filteredList = temp_data.filter((item) =>
              item.status === "Initialized"
            );
            console.log("Student Absent list", filteredList);
          } else if (searched === "Not Scheduled") {
            let purchasedList = temp_data.filter((item) =>
              item.status === "Purchased"
            );
            let initializedList = temp_data.filter((item) =>
              (item.status === "Initialized") && (temp_time > item.student_last_slot_cutoff)
            );
            filteredList = [...purchasedList, ...initializedList];
          } else if (searched === "Done") {
            filteredList = temp_data.filter((item) =>
              item.status === "Done"
            );
            console.log("Student Absent list", filteredList);
          } else if (searched === "Awaited Mentor Feedback") {
            filteredList = temp_data.filter((item) =>
              item.status === "Feedback"
            );
            console.log("Student Absent list", filteredList);
          } else if (searched === "Ongoing") {
            filteredList = temp_data.filter((item) =>
              item.status === "In process"
            );
            console.log("Student Absent list", filteredList);
          } else if (searched === "No Action Students") {
            filteredList = temp_data.filter((item) =>
              item.status === "Purchased"
            );
            console.log("Student Absent list", filteredList);
          } else if (searched === "Mentor Absent") {
            filteredList = temp_data.filter((item) =>
              item.mentor_absent_count !== null
            );
            console.log("Mentor Absent list", filteredList);
          } else if (searched === "Mentor Awaited") {
            filteredList = temp_data?.filter((item) =>
              item.status === "Initialized" && !(temp_time > item.student_last_slot_cutoff)
            );
          } else if (searched === "Awaited Mentor Feedback") {
            filteredList = temp_data?.filter((item) =>
              item.status === "Feedback"
            );
          } else if (searched === "Received Mentor Feedback") {
            filteredList = temp_data.filter((item) =>
              item.mentor_feedback_submitted === 1
            );
          } else if (searched === "All") {
            filteredList = temp_data
            console.log("filtered list", filteredList);
          }
          else {
            filteredList = temp_data.filter((item) =>
              item.student_name?.toLowerCase().includes(searched.toLowerCase()) ||
              item.mentor_name?.toLowerCase().includes(searched.toLowerCase()) || item.student_name?.includes(searched) ||
              item.mentor_name?.includes(searched) || item.student_name?.toUpperCase().includes(searched.toUpperCase()) ||
              item.mentor_name?.toUpperCase().includes(searched.toUpperCase()) ||
              item.student_roll_no?.includes(searched) || item.student_email?.toLowerCase().includes(searched.toLowerCase()) || item.student_email?.toUpperCase().includes(searched.toUpperCase())
              || item.mentor_email?.toLowerCase().includes(searched.toLowerCase()) || item.mentor_email?.toUpperCase().includes(searched.toUpperCase())
            );
          }
          console.log("filtered list", filteredList);
          if (filteredList.length === 0) {
            console.log("i'm here");
            toast.dismiss();
            setCopyList([]);
          } else {
            console.log("now, i'm here");
            toast.dismiss();
            setCopyList(filteredList);
          }
        }

        setLoading(false)

      }

      // forming the data
    }
    handleEffect();
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

  }, [])

  console.log("war room details", warRoomDetailsSelected, studentDetails);

  const collegeDataGetter = async () => {
    try {
      const res = await service.get(`/orderDetailsOfAProcess?process_id=${process_id}`)
      console.log("student details response:", res.data)
      return res.data
    } catch (err) {
      console.log(err)
    }
  }

  const orderHistoryGetter = async (order_id) => {
    try {
      const res = await service.get(`/order_history?order_id=${order_id}`)
      console.log("order details response:", res.order_history)
      return res.order_history
    } catch (err) {
      console.log(err)
    }
  }
  console.log("internet data available", internetSpeedMentor, internetSpeedStudent);

  const studentFeedbackGetter = async () => {
    try {
      const res = await service.get(`/student_feedback?process_id=${process_id}`)
      console.log("student feedback response:", res.data)
      return res.data
    } catch (err) {
      console.log(err)
    }
  }

  const mentorFeedbackGetter = async () => {
    try {
      const res = await service.get(`/mentor_feedback?process_id=${process_id}`)
      console.log("mentor feedback response:", res.data)
      return res
    } catch (err) {
      console.log(err)
    }
  }

  const warRoomDataGetter = async () => {
    try {
      const res = await service.get(`/warRoom?process_id=${process_id}`)
      console.log("war room details response:", res.data)
      return res.data
    } catch (err) {
      console.log(err)
    }
  }

  const internetStatsGetter = async (order_id) => {
    try {
      const res = await services.get(`/session/stats?order_id=${order_id}`)
      console.log("internetStatsGetter response:", res.network_stats)

      return res.network_stats
    } catch (err) {
      console.log(err)
    }
  }

  const findChangedPairs = (orderHistoryDetails) => {
    const updatedData = [...orderHistoryDetails]; // Create a copy of the data array
    let prevValue = updatedData[0].history_timestamp;

    for (let i = 1; i < updatedData.length; i++) {
      const currentTimestamp = updatedData[i].history_timestamp;
      updatedData[i].history_timestamp = prevValue;
      prevValue = currentTimestamp; // Store the original value for the next iteration
    }
    updatedData[0].history_timestamp = updatedData[0].timestamp;
    console.log("manipulated pairs", updatedData);
    orderHistoryDetails = updatedData;

    console.log("orderHistory", orderHistoryDetails);
    let temp_order_history = orderHistoryDetails?.slice(-1)
    setmentor_missed(temp_order_history)
    console.log("the last two order history", temp_order_history);
    const changedPairsArray = [];
    for (let i = 1; i < orderHistoryDetails.length; i++) {
      const previousObject = orderHistoryDetails[i - 1];
      const currentObject = orderHistoryDetails[i];
      if (previousObject.id === currentObject.id) {
        const change = {};
        for (const key in currentObject) {
          if (currentObject[key] !== previousObject[key]) {
            change[key] = {
              previousValue: previousObject[key],
              currentValue: currentObject[key],
            };
          }
        }
        if (!change.hasOwnProperty("history_timestamp")) {
          const lastIndex = i - 1;
          if (lastIndex >= 0) {
            change.history_timestamp = {
              previousValue: orderHistoryDetails[lastIndex].history_timestamp,
              currentValue: currentObject.history_timestamp,
            };
          }
        }
        if (Object.keys(change).length > 0) {
          change.id = currentObject.id;
          changedPairsArray.push(change);
        }
      }
    }
    return changedPairsArray;
  };

  const data_manupulator_duration = (duration) => {
    console.log("duration name", duration);
    let temp = duration;
    temp = temp?.map(bat => {
      bat.key = bat.duration_name;
      bat.value = bat.duration_value;
      return bat;
    });
    console.log("temp rows batch:", temp)
    setDuration_name(temp)
  }

  console.log("duration_name", duration_name);

  const handleStudentsDetailsClick = async (order_id, end_time) => {
    setActivityLoading(true);
    try {
      const res = await service.get(`/activityDetailsOfAStudent?order_id=${order_id}`)
      console.log("student details response:", res.data)
      const temp_data = {
        ...res.data,
        order_details: {
          ...res.data.order_details,
          order_id: order_id
        }
      };
      if (new Date().getTime() - new Date(res.data?.order_details.slot_date).getTime() + 19800000 >= end_time + 900000 && res.data?.order_details.order_flag === "Scheduled") {
        setMentor_missed_Request(true)
      }
      if (res.data?.mentor_feedback?.fields_feedback != null || res.data?.mentor_feedback != null) {
        const feedback_fields = JSON.parse(res.data?.mentor_feedback?.fields_feedback)
        setFeedbackFields(feedback_fields)
      }

      if (res.data?.mentor_feedback?.mcq_feedback != null || res.data?.mentor_feedback != null) {
        const mcq_feedback_fields = JSON.parse(res.data?.mentor_feedback?.mcq_feedback)
        setMcqFeedbackFields(mcq_feedback_fields)
      }

      if (res.data?.student_feedback?.student_feedback != null || res.data?.student_feedback != null) {
        const student_feedback_fields = JSON.parse(res.data?.student_feedback?.student_feedback)
        console.log("student_feedback_fields", student_feedback_fields);
        setStudentFeedbackFields(student_feedback_fields)
      }
      if (res.data.chat !== null) {
        const messages = JSON.parse(res.data?.chat.chat).messages;
        setChatMessages(messages)
      }
      setStudentsDetailsClick(temp_data)
      let orderHistoryDetails = await orderHistoryGetter(order_id)
      console.log("order history details in table", orderHistoryDetails);
      let pairChanged = findChangedPairs(orderHistoryDetails)
      console.log("pair last timestamp", pairChanged[pairChanged.length - 1]?.history_timestamp);
      // setOrderHistory(orderHistoryDetails)
      // const temp_order_history = orderHistoryDetails?.filter()
      setChangedPairs(pairChanged);
      console.log("changed pair", pairChanged);
      const reconsider_request = pairChanged?.filter((item) => item.reconsider)
      const spe_role_request = pairChanged
        ?.filter((item) =>
        (item.spe_id ||
          item.role_id ||
          item.slots ||
          item.jd_id)
        );
      const resume_request = pairChanged
        ?.filter((item) => item.resume);
      const mentor_feedback_request = pairChanged?.filter((item) => item.mentor_feedback_submitted)
      const mentor_joined_request = pairChanged?.filter((item) => item.mentor_joined)
      const mentor_join_time_request = pairChanged?.filter((item) => item.mentor_join_time)
      const is_absent_request = pairChanged?.filter((item) => item.is_absent)
      const student_feedback_request = pairChanged?.filter((item) => item.student_feedback_submitted)
      const student_join_time_request = pairChanged?.filter((item) => item.student_join_time)
      const notifications_request = pairChanged?.filter((item) => item.notification_ids)
      const filtered_student_join_time_data = student_join_time_Request.filter((item) => JSON.parse(item?.student_join_time?.previousValue) == null)

      const order_flag_changed = pairChanged?.filter((item) => item.order_flag)
      console.log("order flag changes", order_flag_changed, spe_role_request, resume_request);
      const allItems = [];
      // Combine all the arrays into a single array
      allItems.push(...order_flag_changed);
      allItems.push(...spe_role_request);
      allItems.push(...resume_request);
      allItems.push(...mentor_feedback_request);
      allItems.push(...mentor_join_time_request);
      allItems.push(...is_absent_request);
      allItems.push(...student_feedback_request);
      allItems.push(...student_join_time_request);
      allItems.push(...reconsider_request);
      allItems.push(...notifications_request);
      console.log("all items array before", allItems);
      allItems.sort((a, b) => {
        const timestampA = new Date(a.history_timestamp?.currentValue).getTime();
        const timestampB = new Date(b.history_timestamp?.currentValue).getTime();
        return timestampA - timestampB;
      });

      let internetStats = await internetStatsGetter(order_id)
      console.log("vbdchs", internetStats);
      const speeds = calculateSpeeds(internetStats?.sessionStatMentor)
      const speedsStudent = calculateSpeedsStudent(internetStats?.sessionStatStudent)
      setInternetSpeedMentor(speeds)
      setInternetSpeedStudent(speedsStudent)
      setInternet_stats(internetStats)

      const filteredSpeed = speeds.filter(
        (item) => !isNaN(item.publishSpeed) || !isNaN(item.subscribeSpeed)
      );

      // for student
      const filteredSpeedStudent = speedsStudent.filter(
        (item) => !isNaN(item.publishSpeed) || !isNaN(item.subscribeSpeed)
      );

      console.log("filteredSpeed", filteredSpeed);

      if (filteredSpeed.length > 0) {
        const publishData = filteredSpeed.map((item) => item.publishSpeed);
        const subscribeData = filteredSpeed.map((item) => item.subscribeSpeed);
        setSubscribeSeriesData(subscribeData);
        setPublishSeriesData(publishData);
      }

      // for student
      if (filteredSpeedStudent.length > 0) {
        const publishDataStudent = filteredSpeedStudent.map((item) => item.publishSpeed);
        const subscribeDataStudent = filteredSpeedStudent.map((item) => item.subscribeSpeed);
        setSubscribeSeriesDataStudent(subscribeDataStudent);
        setPublishSeriesDataStudent(publishDataStudent);
      }

      console.log("all items array after", allItems);
      setAllItems(allItems)
      setReconsiderRequest(reconsider_request)
      setSpecialization_role_slots_jd(spe_role_request)
      setResumeRequest(resume_request)
      setMentor_feedback_submitted_RequestRequest(mentor_feedback_request)
      setMentor_joined_Request(mentor_joined_request)
      setMentor_join_time_Request(mentor_join_time_request)
      setMark_absent_Request(is_absent_request)
      setStudent_join_time_Request(student_join_time_request)
      setStudent_feedback_submitted_Request(student_feedback_request)
      setOrder_flag_changes(order_flag_changed)
      setNotification_ids(notifications_request)
      console.log("reconsider Request pair", reconsider_request);
      callModalToOpen()
      // sortByTimestamp(orderHistoryDetails,reconsider_request,spe_role_request,resume_request,mentor_feedback_request,mentor_joined_request,mentor_join_time_request,is_absent_request,student_feedback_request,student_join_time_request)
    } catch (err) {
      console.log(err)
    }
    setActivityLoading(false);
  }


  const roleTypeGetter = async () => {
    try {
      const res = await service.get('/role?is_admin=1')
      console.log("roleTypeGetter", res.data)
      setRoleTypeData([...res.data])
      return res.data
    }

    catch (err) {
      console.log(err)
    }
  }

  const data_manupulator_roleType = (roleType) => {
    console.log("SKU data:", roleType)
    let temp_role = JSON.parse(JSON.stringify(roleType));
    temp_role = temp_role.map((role) => {
      role.table = "sku"
      return role
    });

    console.log("temp role:", temp_role)
    // setRoleTypeDataMulti(temp_sku)
  }

  const DurationGetter = async () => {
    try {
      const res = await service.get('/duration')
      console.log("Response from backend for duration:", res.durations)
      // setDurationData(getTimeFormated(res.durations))
      return res.durations
    } catch (err) {
      console.log(err)
    }
  }

  const requestSearch = (searched, isSelected) => {
    console.log("searched", searched);
    let filteredList;
    const updatedOptions = options.map(option => ({
      ...option,
      isSelected: option.value === searched ? !option.isSelected : false
    }));
    let temp_time = new Date().getTime()
    console.log("options selected", updatedOptions);
    setOptions(updatedOptions);

    if (searched === "Student Absent") {
      filteredList = warRoomDetailsSelected.filter((item) =>
        item.status === "Done" && item.is_absent === 1
      );
      console.log("Student Absent list", filteredList);
    } else if (searched === "Expired Bookings") {
      filteredList = warRoomDetailsSelected.filter((item) =>
        (item.status === "Initialized") && (temp_time > item.student_last_slot_cutoff)
      );
      console.log("Student Absent list", filteredList);
    } else if (searched === "Scheduled") {
      filteredList = warRoomDetailsSelected.filter((item) =>
        item.status === "Scheduled"
      );
      console.log("Student Absent list", filteredList);
    } else if (searched === "Initialized") {
      filteredList = warRoomDetailsSelected.filter((item) =>
        item.status === "Initialized"
      );
      console.log("Student Absent list", filteredList);
    } else if (searched === "Not Scheduled") {
      let purchasedList = warRoomDetailsSelected.filter((item) =>
        item.status === "Purchased"
      );
      let initializedList = warRoomDetailsSelected.filter((item) =>
        (item.status === "Initialized") && (temp_time > item.student_last_slot_cutoff)
      );
      filteredList = [...purchasedList, ...initializedList];
    }
    else if (searched === "Done") {
      filteredList = warRoomDetailsSelected.filter((item) =>
        item.status === "Done"
      );
      console.log("Student Absent list", filteredList);
    } else if (searched === "Awaited Mentor Feedback") {
      filteredList = warRoomDetailsSelected.filter((item) =>
        item.status === "Feedback"
      );
      console.log("Student Absent list", filteredList);
    } else if (searched === "Ongoing") {
      filteredList = warRoomDetailsSelected.filter((item) =>
        item.status === "In process"
      );
      console.log("Student Absent list", filteredList);
    } else if (searched === "No Action Students") {
      filteredList = warRoomDetailsSelected.filter((item) =>
        item.status === "Purchased"
      );
      console.log("Student Absent list", filteredList);
    } else if (searched === "Mentor Absent") {
      filteredList = warRoomDetailsSelected.filter((item) =>
        item.mentor_absent_count !== null
      );
      console.log("Mentor Absent list", filteredList);
    } else if (searched === "Mentor Awaited") {
      filteredList = warRoomDetailsSelected?.filter((item) =>
        item.status === "Initialized" && !(temp_time > item.student_last_slot_cutoff)
      );
    } else if (searched === "Awaited Mentor Feedback") {
      filteredList = warRoomDetailsSelected?.filter((item) =>
        item.status === "Feedback"
      );
    } else if (searched === "Received Mentor Feedback") {
      filteredList = warRoomDetailsSelected.filter((item) =>
        item.mentor_feedback_submitted === 1
      );
    } else if (searched === "All") {
      filteredList = warRoomDetailsSelected
      console.log("filtered list", filteredList);
    }
    else {
      filteredList = warRoomDetailsSelected.filter((item) =>
        item.student_name?.toLowerCase().includes(searched.toLowerCase()) ||
        item.mentor_name?.toLowerCase().includes(searched.toLowerCase()) || item.student_name?.includes(searched) ||
        item.mentor_name?.includes(searched) || item.student_name?.toUpperCase().includes(searched.toUpperCase()) ||
        item.mentor_name?.toUpperCase().includes(searched.toUpperCase()) ||
        item.student_roll_no?.includes(searched) || item.student_email?.toLowerCase().includes(searched.toLowerCase()) || item.student_email?.toUpperCase().includes(searched.toUpperCase())
        || item.mentor_email?.toLowerCase().includes(searched.toLowerCase()) || item.mentor_email?.toUpperCase().includes(searched.toUpperCase())
      );
    }

    console.log("filtered list", filteredList);
    if (filteredList.length === 0) {
      console.log("i'm here");
      toast.dismiss();
      setCopyList([]);
    } else {
      toast.dismiss();
      console.log("now, i'm here");
      setCopyList(filteredList);
    }
  }

  console.log("click students data", studentsDetailsClick);
  console.log("copyList", copyList);
  console.log("mentor details", mentorFeedbackDetails);
  const fileName = 'download'
  const exportType = 'xls'


  const ExportToExcel = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Mentor Feedback');

    const columnHeaders = Object.keys(mentorFeedbackDetails[0]).slice(1);
    worksheet.addRow(columnHeaders);


    for (let i = 1; i < mentorFeedbackDetails.length; i++) {
      const feedback = mentorFeedbackDetails[i];
      const jsonLength = Object.keys(feedback).length;
      if (jsonLength > 1) {
        const rowData = Object.values(feedback).slice(1);
        worksheet.addRow(rowData);
      }

    }

    const buffer = await workbook.xlsx.writeBuffer();

    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

    if (!is_b2c)
      saveAs(blob, `${college_data_name}_${batchName}_${sku_name}_"Mentor Feedback"_${currentDate.getDate()}-${currentDate.getMonth() + 1}-${currentDate.getFullYear()}_${currentDate.getHours()}-${currentDate.getMinutes()}-${currentDate.getSeconds()}.xlsx`);
    else
      saveAs(blob, `B2C_${sku_name}_"Mentor Feedback"_${currentDate.getDate()}-${currentDate.getMonth() + 1}-${currentDate.getFullYear()}_${currentDate.getHours()}-${currentDate.getMinutes()}-${currentDate.getSeconds()}.xlsx`);
  };


  // const generateExcel = () => {
  //   const data = mentorFeedbackDetails;

  //   const filteredData = data.filter(item => Object.keys(item).length > 1);

  //   const workbook = new ExcelJS.Workbook();
  //   const worksheet = workbook.addWorksheet('Mentor Feedback');

  //   // Create header row
  //   const headerRow = [
  //     'Mentor First Name', 'Mentor Last Name', 'First Name', 'Last Name',
  //     'Email ID', 'Phone Number', ...mentorFeedbackFields.flatMap(field => {
  //       const feedback = filteredData.length > 0 ? JSON.parse(filteredData[0]['0'].fields_feedback).responses.find(response => response.field_name === field.field_name) : null;
  //       if (feedback && typeof feedback.description === 'string') {
  //         return [field.field_name, `${field.field_name} Rating`];
  //       } else {
  //         return [field.field_name];
  //       }
  //     }),
  //     ...mentorFeedbackMCQs.map(mcq => mcq.question)
  //   ];
  //   worksheet.addRow(headerRow);

  //   // Add data rows
  //   filteredData.forEach((item, index) => {
  //     const rowData = [
  //       item.mentor_first_name, item.mentor_last_name,
  //       item.first_name, item.last_name,
  //       item.email_id, item.ph_no,
  //       ...mentorFeedbackFields.flatMap(field => {
  //         const feedback = JSON.parse(item['0'].fields_feedback).responses.find(response => response.field_name === field.field_name);
  //         if (!feedback) {
  //           return ['', ''];
  //         }
  //         if (typeof feedback.description === 'string') {
  //           // Handle type 1
  //           return [feedback.description, feedback.rating];
  //         } else if (typeof feedback.description === 'object' && feedback.description.bullets) {
  //           // Handle type 2
  //           return [feedback.description.bullets.join('\n')];
  //         }
  //         return ['', ''];
  //       }),
  //       ...mentorFeedbackMCQs.map(mcq => {
  //         const feedback = JSON.parse(item['0'].mcq_feedback).responses.find(response => response.question === mcq.question);
  //         return feedback ? feedback.ans : '';
  //       })
  //     ];
  //     worksheet.addRow(rowData);
  //   });

  //   // Generate Excel file
  //   workbook.xlsx.writeBuffer().then(buffer => {
  //     const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  //     const url = window.URL.createObjectURL(blob);
  //     const link = document.createElement('a');
  //     link.href = url;
  //     link.download = is_b2c ?
  //       `B2C_${sku_name}_"Mentor Feedback"_${currentDate.getDate()}-${currentDate.getMonth() + 1}-${currentDate.getFullYear()}_${currentDate.getHours()}-${currentDate.getMinutes()}-${currentDate.getSeconds()}.xlsx`
  //       :
  //       `${college_data_name}_${batchName}_${sku_name}_"Mentor Feedback"_${currentDate.getDate()}-${currentDate.getMonth() + 1}-${currentDate.getFullYear()}_${currentDate.getHours()}-${currentDate.getMinutes()}-${currentDate.getSeconds()}.xlsx`;
  //     link.click();
  //   });
  // };

  const generateExcel = async () => {
    let mentorFeedback = await mentorFeedbackGetter()
    setMentorFeedbackDetails(mentorFeedback.data)
    setMentorFeedbackFields(mentorFeedback.mentor_feedback_feilds)
    setMentorFeedbackMCQs(mentorFeedback.mentor_feedback_mcqs)
    const data = mentorFeedback.data;

    // Filter out items with at least one key
    const filteredData = data.filter(item => Object.keys(item).length > 1);

    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Mentor Feedback');

    // Create header row
    const headerRow = [
      'Mentor First Name', 'Mentor Last Name', 'First Name', 'Last Name',
      'Email ID', 'Phone Number',
      ...mentorFeedback.mentor_feedback_feilds.flatMap(field => {
        const feedback = filteredData.length > 0 ? JSON.parse(filteredData[0]['0'].fields_feedback).responses.find(response => response.field_name === field.field_name) : null;
        if (feedback && typeof feedback.description === 'object' && feedback.description.bullets) {
          // Type 2 feedback: include only the field name
          return [field.field_name];
        } else if (feedback && typeof feedback.description === 'string') {
          // Type 1 feedback: include both field name and Rating column
          return [field.field_name, `${field.field_name} Rating`];
        }
        return [field.field_name];
      }),
      ...mentorFeedback.mentor_feedback_mcqs.map(mcq => mcq.question)
    ];
    worksheet.addRow(headerRow);

    // Add data rows
    filteredData.forEach(item => {
      const feedbackResponses = JSON.parse(item['0'].fields_feedback).responses;
      const mcqResponses = JSON.parse(item['0'].mcq_feedback).responses;

      const rowData = [
        item.mentor_first_name, item.mentor_last_name,
        item.first_name, item.last_name,
        item.email_id, item.ph_no,
        ...mentorFeedback.mentor_feedback_feilds.flatMap(field => {
          const feedback = feedbackResponses.find(response => response.field_name === field.field_name);
          if (!feedback) {
            // Handle missing feedback
            return [''];
          }
          if (typeof feedback.description === 'string') {
            // Type 1 feedback
            return [feedback.description, feedback.rating || '']; // Include rating if available
          } else if (typeof feedback.description === 'object' && feedback.description.bullets) {
            // Type 2 feedback
            const bullets = feedback.description.bullets.join('\n'); // Join bullets into a single string
            return [bullets]; // Only include bullets
          }
          return [''];
        }),
        ...mentorFeedback.mentor_feedback_mcqs.map(mcq => {
          const feedback = mcqResponses.find(response => response.question === mcq.question);
          return feedback ? feedback.ans : '';
        })
      ];

      worksheet.addRow(rowData);
    });

    // Generate Excel file
    workbook.xlsx.writeBuffer().then(buffer => {
      const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = is_b2c ?
        `B2C_${sku_name}_"Mentor Feedback"_${currentDate.getDate()}-${currentDate.getMonth() + 1}-${currentDate.getFullYear()}_${currentDate.getHours()}-${currentDate.getMinutes()}-${currentDate.getSeconds()}.xlsx`
        :
        `${college_data_name}_${batchName}_${sku_name}_"Mentor Feedback"_${currentDate.getDate()}-${currentDate.getMonth() + 1}-${currentDate.getFullYear()}_${currentDate.getHours()}-${currentDate.getMinutes()}-${currentDate.getSeconds()}.xlsx`;
      link.click();
    });
  };

  const fileDownload = () => {
    console.log("Downloading the file");
    console.log("Rows data now is: ", studentDetails);

    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Data');

    const hardcodedRow = ['order_id', 'student_name', 'student_email', 'student_phone', 'student_roll_no', 'status', 'mentor_name', 'mentor_email', 'mentor_phone', 'mentor_linkedin', 'act_type_name', 'duration', 'slot_date', 'start_time', 'end_time', 'is_absent', 'mentor_feedback_submitted', 'sku_name', 'oop', 'role_name', 'specialization_name'];
    const keysToExclude = new Set(['act_type_id', 'bookable_status', 'role_id', 'sku_id']);
    const filteredHeaders = hardcodedRow.filter(header => !keysToExclude.has(header));
    worksheet.addRow(filteredHeaders);
    // Add the column headers
    // worksheet.addRow([...hardcodedRow]);

    // Add data rows
    const dataList = copyList && copyList.length > 0 ? copyList : studentDetails;

    dataList.forEach(row => {
      const rowData = filteredHeaders.map(header => {
        if (header === 'slot_date') {
          return extractDateFromDateString(row['start_time']); // Assumes start_time is stored directly in row
        } else if (header === 'start_time') {
          return calculateEpochTimeInLocalTime(row['start_time']);
        } else if (header === 'end_time') {
          return calculateEpochTimeInLocalTime(row['end_time']);
        }
        return row.hasOwnProperty(header) ? row[header] : '';
      });
      worksheet.addRow(rowData);
    });

    // Save workbook to a Buffer
    workbook.xlsx.writeBuffer().then((buffer) => {
      // Convert Buffer to Blob
      const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

      // Save the Blob as a downloadable file
      const currentDate = new Date();
      const filename = is_b2c ?
        `B2C_${sku_name}_"Mentor Feedback"_${currentDate.getDate()}-${currentDate.getMonth() + 1}-${currentDate.getFullYear()}_${currentDate.getHours()}-${currentDate.getMinutes()}-${currentDate.getSeconds()}.xlsx`
        : `${college_data_name}_${batchName}_${sku_name}_${searched}_${currentDate.getDate()}-${currentDate.getMonth() + 1}-${currentDate.getFullYear()}-${currentDate.getHours()}-${currentDate.getMinutes()}-${currentDate.getSeconds()}.xlsx`;
      saveAs(blob, filename);
    });
  };


  const completeActivityDownload = () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Data');

    const hardcodedRow = ['order_id', 'student_name', 'student_email', 'student_phone', 'student_roll_no', 'status', 'mentor_name', 'mentor_email', 'mentor_phone', 'mentor_linkedin', 'act_type_name', 'duration', 'slot_date', 'start_time', 'end_time', 'is_absent', 'mentor_feedback_submitted', 'sku_name', 'oop', 'role_name', 'specialization_name'];
    const keysToExclude = new Set(['act_type_id', 'bookable_status', 'role_id', 'sku_id']);
    const filteredHeaders = hardcodedRow.filter(header => !keysToExclude.has(header));
    worksheet.addRow(filteredHeaders);
    // Add the column headers
    // worksheet.addRow([...hardcodedRow]);

    // Add data rows

    studentDetails.forEach(row => {
      const rowData = filteredHeaders.map(header => {
        if (header === 'slot_date') {
          return extractDateFromDateString(row['start_time']); // Assumes start_time is stored directly in row
        } else if (header === 'start_time') {
          return calculateEpochTimeInLocalTime(row['start_time']);
        } else if (header === 'end_time') {
          return calculateEpochTimeInLocalTime(row['end_time']);
        }
        return row.hasOwnProperty(header) ? row[header] : '';
      });
      worksheet.addRow(rowData);
    });

    // Save workbook to a Buffer
    workbook.xlsx.writeBuffer().then((buffer) => {
      // Convert Buffer to Blob
      const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

      // Save the Blob as a downloadable file
      const currentDate = new Date();
      const filename = is_b2c ?
        `B2C_${sku_name}_"Mentor Feedback"_${currentDate.getDate()}-${currentDate.getMonth() + 1}-${currentDate.getFullYear()}_${currentDate.getHours()}-${currentDate.getMinutes()}-${currentDate.getSeconds()}.xlsx`
        : `${college_data_name}_${batchName}_${sku_name}_"Activity Details"_${currentDate.getDate()}-${currentDate.getMonth() + 1}-${currentDate.getFullYear()}-${currentDate.getHours()}-${currentDate.getMinutes()}-${currentDate.getSeconds()}.xlsx`;
      saveAs(blob, filename);
    });

    // console.log("Downloading the file");
    // console.log("Rows data now is:  ", studentDetails);
    // const hardcodedRow = ['order_id', 'student_name', 'student_email', 'student_phone', 'student_roll_no', 'order_flag', 'mentor_name', 'mentor_email', 'mentor_phone', 'act_type_id', 'act_type_name', 'bookable_status', 'duration', 'end_time', 'is_absent', 'mentor_feedback_submitted', 'mentor_id', 'name', 'oop', 'role_id', 'sku_id', 'slot_date', 'slot_name', 'slots', 'spe_id', 'start_time'];
    // const csvData = [hardcodedRow.join(","), ...studentDetails.map(row => Object.values(row).join(","))].join("\n");
    // const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
    // saveAs(blob, `${college_data_name}_${batchName}_${sku_name}_"Activity Details"_${currentDate.getDate() + "-" + currentDate.getMonth() + "-" + currentDate.getFullYear() + "-" + currentDate.getHours() + ":" + currentDate.getMinutes() + ":" + currentDate.getSeconds()}.csv`);
  }



  const studentFeedbackDownload = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Student Feedback');

    const columnHeaders = Object.keys(studentFeedbackDetails[0]);
    worksheet.addRow(columnHeaders);
    for (let i = 1; i < studentFeedbackDetails.length; i++) {
      const feedback = studentFeedbackDetails[i];
      const rowData = Object.values(feedback);
      worksheet.addRow(rowData);
    }
    // studentFeedbackDetails.forEach((feedback) => {
    //   const rowData = Object.values(feedback);
    //   worksheet.addRow(rowData);
    // });


    const buffer = await workbook.xlsx.writeBuffer();

    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

    if (is_b2c)
      saveAs(blob, `B2C_${sku_name}_"Mentor Feedback"_${currentDate.getDate()}-${currentDate.getMonth() + 1}-${currentDate.getFullYear()}_${currentDate.getHours()}-${currentDate.getMinutes()}-${currentDate.getSeconds()}.xlsx`);
    else
      saveAs(blob, `${college_data_name}_${batchName}_${sku_name}_"Student Feedback"_${currentDate.getDate()}-${currentDate.getMonth() + 1}-${currentDate.getFullYear()}_${currentDate.getHours()}-${currentDate.getMinutes()}-${currentDate.getSeconds()}.xlsx`);
    // const hardcodedRow = ['Rating', 'Comment', 'Question', 'Answer'];
    // // const csvData = [hardcodedRow.join(","), ...studentDetails.map(row => Object.values(row).join(","))].join("\n");

    // const csvData = [hardcodedRow.join(","), ...studentFeedbackDetails.map(row => Object.values(row).join(","))].join("\n");
    // const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
    // saveAs(blob, `${college_data_name}_${batchName}_${sku_name}_"Student Feedback"_${currentDate.getDate() + "-" + currentDate.getMonth() + "-" + currentDate.getFullYear() + "-" + currentDate.getHours() + ":" + currentDate.getMinutes() + ":" + currentDate.getSeconds()}.csv`);
  }

  const goBack = () => {
    console.log("Back Button is Clicked Now")
    navigate(-1);
  }


console.log("infoo",roleTypeData)

  return (
    <>
      {loading ?
        <SmallPageLoader show={true} /> :
        <>
          <div className="student-details-parent" style={{ marginTop: "3rem" }}>
            <ToastContainer position="top-center" limit={1} />
              <div className="topHeadings" style={{ display: "flex" }}>
                <ArrowBackIcon className="Arrow-back-btn" style={{ height: "30px", width: "30px", marginRight: "0.5rem" }} onClick={() => goBack()} />
                {!isAnomalies && (!is_b2c || college_data_name) ? <>
                  <p style={{ color: Color.neutralMidGrey }}>{college_data_name}</p>
                  <p style={{ marginLeft: '1rem', marginRight: '1rem' }}>{" "} {">"}{" "} </p>
                </> :<p style={{ color: Color.neutralMidGrey }}> Order Details</p>}
                {!isAnomalies && (!is_b2c || batchName) ? <>
                  <p>{batchName}</p>
                  <p style={{ marginLeft: '1rem', marginRight: '1rem' }}>{" "} {">"}{" "} </p></> : <></>}
                {(is_b2c) ? <>
                  <p>B2C</p>
                  <p style={{ marginLeft: '1rem', marginRight: '1rem' }}>{" "} {">"}{" "} </p></> : <></>}
                <p>{sku_name}</p></div>

            {loading ? (<div style={{ position: "absolute", justifyContent: "center", alignItems: "center", margin: '0 auto', marginLeft: "40%", marginTop: "10%" }}><Loader show={loading} /></div>) : (
              <>
                {!isAnomalies && (

                  <div style={{ display: "flex" }}>
                    <CardActions sx={{ justifyContent: 'flex-start', bottom: 0, padding: "0px", paddingTop: "1rem", marginRight: '1rem' }}>
                      <button
                        className="submitBtn"
                        type="button"
                        style={{ height: "44px", bottom: 0, width: "auto", paddingLeft: "0.5rem", paddingRight: '0.5rem' }}
                        onClick={() => completeActivityDownload()}
                      >
                        Download War Room Details
                      </button>
                    </CardActions>
                    <CardActions sx={{ justifyContent: 'flex-start', bottom: 0, padding: "0px", paddingTop: "1rem", marginRight: '1rem' }}>
                      <button
                        className="submitBtn"
                        type="button"
                        style={{ height: "44px", bottom: 0, width: "auto", paddingLeft: "0.5rem", paddingRight: '0.5rem' }}
                        onClick={generateExcel}
                      >
                        Mentor Feedback
                      </button>
                    </CardActions>
                    <CardActions sx={{ justifyContent: 'flex-start', bottom: 0, padding: "0px", paddingTop: "1rem" }}>
                      <button
                        className="submitBtn"
                        type="button"
                        style={{ height: "44px", bottom: 0, width: "auto", paddingLeft: "0.5rem", paddingRight: '0.5rem' }}
                        onClick={() => studentFeedbackDownload()}
                      >
                        Student Feedback
                      </button>
                    </CardActions>
                  </div>
                )}
                <div style={{ display: "flex", marginTop: "2rem" }}>
                  <div className='searchTab' style={{ alignItems: 'center', width: "100%", height: "30px", marginBottom: ".5rem" }}>
                    <input style={{ border: "none", height: "30px", width: "100%" }}
                      className='searchingText searchTab'
                      type='text'
                      onChange={(e) => requestSearch(e.target.value)}
                      placeholder='Search for name, roll number, etc'
                    />
                  </div>
                  <DownloadIcon style={{ color: Color.primary1, marginLeft: ".5rem", cursor: "pointer" }} onClick={() => fileDownload()} />
                </div>
                {!isAnomalies && (
                  <div>
                    {options.map((option, index) => (
                      <button
                        key={index}
                        className={option.isSelected === true ? "optionsFocusButton" : "Optionsbutton"}
                        style={{}}
                        onClick={() => {
                          requestSearch(option.value, !option.isSelected)
                        }}
                      >
                        {option.label}
                      </button>
                    ))}
                  </div>
                )}

                <TableContainer>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        {tableTitles.map((title, index) => (
                          <TableCell align='center'
                            key={index} sx={{ fontSize: 16, color: Color.neutralMidGrey }}
                          >
                            {title}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {copyList?.map((details, index) => {
                        return (
                          <>
                            <TableRow onClick={() => handleStudentsDetailsClick(details.order_id, details.end_time)} style={{ cursor: "pointer" }}>
                              <TableCell align='center' sx={{ fontSize: 16, color: Color.neutralMidGrey }}>
                                <p style={{ color: "black" }}>{details.student_name} </p>
                                {details.student_email ? details.student_email : "N/A"}<br />
                                {details.student_phone ? details.student_phone : 'N/A'}<br />
                                {details.student_roll_no ? details.student_roll_no : "N/A"}
                              </TableCell>

                              <TableCell align='center' sx={{ fontSize: 16, color: Color.neutralMidGrey }}>
                                <p style={{ color: "black" }}>{details.mentor_name} </p>
                                {details.mentor_email ? details.mentor_email : "N/A"}<br />
                                {details.mentor_phone ? details.mentor_phone : "N/A"}
                              </TableCell>
                              <TableCell align='center' sx={{ fontSize: 16 }}>
                                {extractDateFromDateString(details?.start_time)}<br />
                                {calculateEpochTimeInLocalTime(details?.start_time)} - {calculateEpochTimeInLocalTime(details?.end_time)}
                              </TableCell>
                              <TableCell align='center' sx={{ fontSize: 16 }}>
                                {details.role_id ? roleTypeData.filter((item_s, index_s) => item_s.id === parseInt(details?.role_id))[0]?.name : "-"}
                              </TableCell>
                              <TableCell align='center' sx={{ fontSize: 16 }}>
                                {details.status}
                              </TableCell>
                            </TableRow>
                          </>
                        )
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </>
            )}
          </div>
          <ActivityLoader show={activityLoading} />
          <ModalSt publishseriesdata={publishSeriesData} setMentor_missed_Request={setMentor_missed_Request} mentor_missed={mentor_missed} publishseriesdatastudent={publishSeriesDataStudent} subscribeseriesdatastudent={subscribeSeriesDataStudent} subscribeseriesdata={subscribeSeriesData} internetSpeedStudent={internetSpeedStudent} internetSpeedMentor={internetSpeedMentor} studentFeedbackFields={studentFeedbackFields} mcqFeedbackFields={mcqFeedbackFields} feedbackFields={feedbackFields} studentsDetailsClick={studentsDetailsClick} setStudentsDetailsClick={setStudentsDetailsClick} show={openModal} setShow={setOpenModal} orderHistory={orderHistory} changedPairs={changedPairs} reconsiderRequest={reconsiderRequest} specialization_role_slots_jd={specialization_role_slots_jd} chatMessages={chatMessages} setChatMessages={setChatMessages} resumeRequest={resumeRequest} mentor_feedback_submitted_Request={mentor_feedback_submitted_Request} mentor_join_time_Request={mentor_join_time_Request} mentor_joined_Request={mentor_joined_Request} mark_absent_Request={mark_absent_Request} student_feedback_submitted_Request={student_feedback_submitted_Request} duration_name={duration_name} student_join_time_Request={student_join_time_Request} mentor_missed_Request={mentor_missed_Request} allItems={allItems} order_flag_changes={order_flag_changes} notification_ids={notification_ids} internet_stats={internet_stats} />
        </>}
    </>
  );
};

export default CreateTable;
import { Box, Button, CircularProgress, IconButton, InputAdornment, TextField, Typography, Skeleton } from "@mui/material";
import { Color, DivCenter, styles } from "../../../../GlobalStyles";
import { AddOutlined, GetApp, SaveAltOutlined, SaveAs, Search, UploadFile } from "@mui/icons-material";
import { useEffect, useState } from "react";
import CCService from "../../../../services/httpServiceWithoutSuperAdmin";
import { epochToDate, formatConvertermonthfirst } from "../../../../services/specialCharReplace";
import { scrollToTargetId } from "../../../Reusable_functions/ScrollToTargetId/ScrollToTargetId";
import { extractDateFromDateString, calculateEpochTimeInLocalTime } from '../../../../services/specialCharReplace';
import { SearchFunction } from "../../../Reusable_functions/TextFunctions/SearchFunction";
import Papa from "papaparse";
import { saveAs } from "file-saver";
import ExcelJS from 'exceljs';
import Tooltip from '@mui/material/Tooltip';
const service = new CCService();
export default function StudentMentorDetailsModule({ markAsDone, process_id, swapped_student, setSwappedStudent, datesFilter, studentMentorDetails, setStudentMentorDetails, getStudentMentorDetails, setChooseMentor, setSwappingStudents, mentorStatList, handleMainEffect, getStatLists, dialogue, setDialogue }) {
    console.log("StudentMentorDetailsModule ~ studentMentorDetails:", studentMentorDetails, markAsDone)

    const [loading, setLoading] = useState({ lazyLoading: false, search: false });
    console.log("swapped_student:", swapped_student)
    //data variable
    const [searchTerm, setSearchTerm] = useState();
    const [filterButtons, setFilterButtons] = useState({ 'All': true });
    const [filteredMainData, setFilteredMainData] = useState([...studentMentorDetails]);
    const [mentorFeedbackDetails, setMentorFeedbackDetails] = useState([])
    const [studentFeedbackDetails, setStudentFeedbackDetails] = useState([])
    console.log("filter buttons", filterButtons)
    const [studentDetails, setStudentDetails] = useState([])
    const [mentorFeedbackFields, setMentorFeedbackFields] = useState([]);
    const [mentorFeedbackMCQs, setMentorFeedbackMCQs] = useState([]);
    //lazy loading
    const [currentPage, setCurrentPage] = useState(1);
    const [endArray, setEndArray] = useState(false);
    const [backgroundColor, setBackgroundColor] = useState("");

    //FUNCTIONS
    useEffect(() => {
        if (currentPage !== 1) loadMoreData(currentPage);
    }, [currentPage]);

    useEffect(() => {
        const handleFilter = () => {
            let temp_addon = { ...filterButtons }
            if (datesFilter && datesFilter.length >= 1) {
                datesFilter?.map(({ session_date }) => {
                    temp_addon[parseInt(session_date)] = false
                }
                )
            }
            console.log("dates inside temp :", temp_addon)
            setFilterButtons(temp_addon)
        }
        handleFilter();
    }, [datesFilter])

    console.log("dates filter:", datesFilter, filterButtons)
    useEffect(() => {
        filteredMainData.forEach((student, index) => {
            handleBackgroundColorChange(student.id, index);
        });
    }, [filteredMainData, swapped_student]);
    useEffect(() => {
        const handleeffect = async () => {
            await collegeDataGetter();
            await studentFeedbackGetter();
            await mentorFeedbackGetter();
        }
        handleeffect();
    }, []);
    const collegeDataGetter = async () => {
        try {
            const res = await service.get(`/orderDetailsOfAProcess?process_id=${process_id}`)
            console.log("student details response:", res.data)
            setStudentDetails(res.data);
            return res.data
        } catch (err) {
            console.log(err)
        }
    }
    const studentFeedbackGetter = async () => {
        try {
            const res = await service.get(`/os/student/feedbackResponse?process_id=${process_id}`)
            console.log("student feedback response:", res.data)
            setStudentFeedbackDetails(res.data);
            return res.data
        } catch (err) {
            console.log(err)
        }
    }

    const mentorFeedbackGetter = async () => {
        try {
            const res = await service.get(`/os/mentor/detailedMentorFeedback?process_id=${process_id}`)
            console.log("mentor feedback response:", res.data)
            setMentorFeedbackDetails(res.data);
            return res
        } catch (err) {
            console.log(err)
        }
    }

    const studentFeedbackDownload = async () => {
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Student Feedback');

        const columnHeaders = Object.keys(studentFeedbackDetails[0]);
        worksheet.addRow(columnHeaders);
        for (let i = 1; i < studentFeedbackDetails.length; i++) {
            const feedback = studentFeedbackDetails[i];
            const rowData = Object.values(feedback);
            worksheet.addRow(rowData);
        }
        // studentFeedbackDetails.forEach((feedback) => {
        //   const rowData = Object.values(feedback);
        //   worksheet.addRow(rowData);
        // });


        const buffer = await workbook.xlsx.writeBuffer();

        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

        saveAs(blob, `Student Feedback.xlsx`);
        // const hardcodedRow = ['Rating', 'Comment', 'Question', 'Answer'];
        // // const csvData = [hardcodedRow.join(","), ...studentDetails.map(row => Object.values(row).join(","))].join("\n");

        // const csvData = [hardcodedRow.join(","), ...studentFeedbackDetails.map(row => Object.values(row).join(","))].join("\n");
        // const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
        // saveAs(blob, `${college_data_name}_${batchName}_${sku_name}_"Student Feedback"_${currentDate.getDate() + "-" + currentDate.getMonth() + "-" + currentDate.getFullYear() + "-" + currentDate.getHours() + ":" + currentDate.getMinutes() + ":" + currentDate.getSeconds()}.csv`);
    }
    const generateExcel = async () => {
        let mentorFeedback = await mentorFeedbackGetter()
        setMentorFeedbackDetails(mentorFeedback.data)
        setMentorFeedbackFields(mentorFeedback.mentor_feedback_feilds)
        setMentorFeedbackMCQs(mentorFeedback.mentor_feedback_mcqs)
        const data = mentorFeedback.data;

        // Filter out items with at least one key
        const filteredData = data.filter(item => Object.keys(item).length > 1);

        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Mentor Feedback');

        // Create header row
        const headerRow = [
            'Mentor First Name', 'Mentor Last Name', 'First Name', 'Last Name',
            'Email ID', 'Phone Number',
            ...mentorFeedback.mentor_feedback_feilds.flatMap(field => {
                const feedback = filteredData.length > 0 ? JSON.parse(filteredData[0]['0'].fields_feedback).responses.find(response => response.field_name === field.field_name) : null;
                if (feedback && typeof feedback.description === 'object' && feedback.description.bullets) {
                    // Type 2 feedback: include only the field name
                    return [field.field_name];
                } else if (feedback && typeof feedback.description === 'string') {
                    // Type 1 feedback: include both field name and Rating column
                    return [field.field_name, `${field.field_name} Rating`];
                }
                return [field.field_name];
            }),
            ...mentorFeedback.mentor_feedback_mcqs.map(mcq => mcq.question)
        ];
        worksheet.addRow(headerRow);

        // Add data rows
        filteredData.forEach(item => {
            const feedbackResponses = JSON.parse(item['0'].fields_feedback).responses;
            const mcqResponses = JSON.parse(item['0'].mcq_feedback).responses;

            const rowData = [
                item.mentor_first_name, item.mentor_last_name,
                item.first_name, item.last_name,
                item.email_id, item.ph_no,
                ...mentorFeedback.mentor_feedback_feilds.flatMap(field => {
                    const feedback = feedbackResponses.find(response => response.field_name === field.field_name);
                    if (!feedback) {
                        // Handle missing feedback
                        return [''];
                    }
                    if (typeof feedback.description === 'string') {
                        // Type 1 feedback
                        return [feedback.description, feedback.rating || '']; // Include rating if available
                    } else if (typeof feedback.description === 'object' && feedback.description.bullets) {
                        // Type 2 feedback
                        const bullets = feedback.description.bullets.join('\n'); // Join bullets into a single string
                        return [bullets]; // Only include bullets
                    }
                    return [''];
                }),
                ...mentorFeedback.mentor_feedback_mcqs.map(mcq => {
                    const feedback = mcqResponses.find(response => response.question === mcq.question);
                    return feedback ? feedback.ans : '';
                })
            ];

            worksheet.addRow(rowData);
        });

        // Generate Excel file
        workbook.xlsx.writeBuffer().then(buffer => {
            const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download =
                `mentor_feedback.xlsx`;
            link.click();
        });
    };
    const fileDownload = () => {
        console.log("Downloading the file");
        console.log("Rows data now is: ", studentDetails);

        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Data');

        const hardcodedRow = ['order_id', 'student_name', 'student_email', 'student_phone', 'student_roll_no', 'status', 'mentor_name', 'mentor_email', 'mentor_phone', 'mentor_linkedin', 'act_type_name', 'duration', 'slot_date', 'start_time', 'end_time', 'is_absent', 'mentor_feedback_submitted', 'sku_name', 'oop', 'role_name', 'specialization_name'];
        const keysToExclude = new Set(['act_type_id', 'bookable_status', 'role_id', 'sku_id']);
        const filteredHeaders = hardcodedRow.filter(header => !keysToExclude.has(header));
        worksheet.addRow(filteredHeaders);
        // Add the column headers
        // worksheet.addRow([...hardcodedRow]);

        // Add data rows


        const dataList = studentDetails;

        dataList.forEach(row => {
            const rowData = filteredHeaders.map(header => {
                if (header === 'slot_date') {
                    return extractDateFromDateString(row['start_time']); // Assumes start_time is stored directly in row
                } else if (header === 'start_time') {
                    return calculateEpochTimeInLocalTime(row['start_time']);
                } else if (header === 'end_time') {
                    return calculateEpochTimeInLocalTime(row['end_time']);
                }
                return row.hasOwnProperty(header) ? row[header] : '';
            });
            worksheet.addRow(rowData);
        });

        // Save workbook to a Buffer
        workbook.xlsx.writeBuffer().then((buffer) => {
            // Convert Buffer to Blob
            const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

            // Save the Blob as a downloadable file
            const currentDate = new Date();
            const filename = `feedback.xlsx`;
            saveAs(blob, filename);
        });
    };

    const downloadStudentMentorDetailsSummary = async (details) => {

        try {
            const response = await service.get(`/os/superadmin/osOrderTable?process_id=${process_id}`);
            let temp_filteredData = [...response.data];


            const mapped_temp = temp_filteredData?.map((item) => {
                console.log("reponse:", item)


                delete item["specialization_deadline"];
                return {
                    ...item,
                    start_time: item?.start_time ? calculateEpochTimeInLocalTime(item.start_time) : "",
                    end_time: item?.end_time ? calculateEpochTimeInLocalTime(item.end_time) : "",
                    start_date: item?.start_time ? epochToDate(item.start_time) : "",
                    end_date: item?.end_time ? epochToDate(item.end_time) : "",
                }
            })

            const temp = mapped_temp?.map((item) => {

                return {
                    ...item,
                }
            })

            //   const outputObject = assessment_key.reduce((result, currentObj) => {
            //     const key = Object.keys(currentObj)[0]; // Assuming each object has only one key
            //     const value = currentObj[key];
            //     result[key] = value;
            //     return result;
            //   }, {});

            //   return {
            //     [`Candidate Name`]: `${item.first_name} ${item.last_name}`,
            //     College: item.college,
            //     ...outputObject,
            //     Competency: item.competency_score,
            //     Resume: item.resume_urls,
            //   };
            // });
            console.log("temp:", temp,);
            const csvData = Papa.unparse(temp);

            const blob = new Blob([csvData], { type: "text/csv" });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = `Student_MentorList_Summary.csv`;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        }
        catch (err) {
            console.log("error in download csv:", err)
        }
    };
    const loadMoreData = async (page) => {
        setLoading((prev) => ({ ...prev, lazyLoading: true }));
        const temp_filter = filterButtons
        const response = await getStudentMentorDetails(page, searchTerm, Object.keys(filterButtons).find((item) => filterButtons[item] == true));
        console.log("loadMoreData ~ response:", response, studentMentorDetails);
        if (response.length === 0) {
            setEndArray(true);
        }
        if (page !== 1) {
            setStudentMentorDetails([...studentMentorDetails, ...response]);
            setFilteredMainData([...filteredMainData, ...response]);
        }
        // console.count('[GET] call');
        setLoading((prev) => ({ ...prev, lazyLoading: false }));
    }

    const handleScroll = (event) => {
        const { scrollHeight, scrollTop, clientHeight } = event.target;
        console.log("handleScroll ~ event:", event, scrollTop, clientHeight, scrollHeight)

        if (scrollTop + clientHeight + 30 >= scrollHeight && !loading.lazyLoading) {
            if (!endArray) {
                setCurrentPage((prevPage) => prevPage + 1);
                loadMoreData(currentPage + 1);
            }
            // else window.alert('Page end')
        }
    };
    function getStatus(order_flag, is_absent, student_availability) {
        if (is_absent === 1) {
            return (
                <div
                    style={{
                        padding: "8px 10px",
                        backgroundColor: "#E7E7E7",
                        color: Color.neutralDarkGrey,
                        fontSize: "12px",
                        borderRadius: "16px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <div>Absent</div>
                </div>
            );
        } else if (order_flag === "Purchased") {
            return (
                <div
                    style={{
                        // padding: "8px 10px",
                        // backgroundColor: "#FCEFD8",
                        color: "#E58A0A",
                        fontSize: "12px",
                        // borderRadius: "16px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <div>Specialization not selected</div>
                </div>
            );
        } else if (order_flag === "Scheduled")
            return (
                <div
                    style={{
                        padding: "8px 10px",
                        backgroundColor: "#E8F3EA",
                        color: Color.green,
                        fontSize: "12px",
                        borderRadius: "16px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }} >
                    <div>Scheduled</div>
                </div>
            );
        else if (order_flag === "Initialized") {
            return (
                <div
                    style={{
                        // padding: "8px 10px",
                        // backgroundColor: "#FCEFD8",
                        color: "#E58A0A",
                        fontSize: "12px",
                        // borderRadius: "16px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",

                    }}
                >
                    <div>{student_availability || student_availability === 0 ? "Slot not selected yet" : "Classes not selected"}</div>
                </div>
            );
        } else if (order_flag === "Done") {
            return (
                <div>
                    <div

                        style={{
                            fontSize: "12px",
                            marginBottom: "12px",
                            color: Color.green,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        Completed
                    </div>

                </div>
            );
        } else if (order_flag === "In process") {
            return (
                <div
                    style={{
                        padding: "8px 10px",
                        backgroundColor: "#FCEFD8",
                        color: "#E58A0A",
                        fontSize: "12px",
                        borderRadius: "16px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",

                    }}
                >
                    <div>In process</div>
                </div>
            );
        } else if (order_flag === "Feedback") {
            return (
                <div>
                    <div
                        // onClick={() =>
                        //   navigate(`/FeedbackReview?order_id=${id}`, {
                        //     state: order_data[0],
                        //   })
                        // }
                        style={{
                            fontSize: "12px",
                            marginBottom: "12px",
                            color: Color.red,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        Pending Feedback
                    </div>

                </div>
            );
        }
    }

    // useEffect(() => {
    //     handleFilterEffect();
    // }, [filterButtons['All'], filterButtons['Not Scheduled'], filterButtons['Scheduled'], filterButtons['HR'], filterButtons['Finance']]);

    // const handleFilterEffect = () => {
    //     let flag = '';
    //     for (const key in filterButtons) {
    //         if (filterButtons[key] === true) flag = key;
    //     }
    //     handleFilterButton(flag, false);
    // }
    console.log("hujhjhh", filteredMainData)
    const handleChangeSearch = (e, newValue) => {
        console.log("handleChangeSearch ~ newValue:", e.target.value, newValue)
        const searchTerm = e.target.value;
        setSearchTerm(searchTerm);
        // if (searchTerm) {
        //     // // FRONTEND - SEARCH
        //     // const filtered = SearchFunction(studentMentorDetails,
        //     //     ['student_name', 'mentor_name', 'student_email', 'student_ph_no',
        //     //         'spe_name', 'mentor_email', 'mentor_ph_no'], searchTerm);
        //     // console.log("handleChangeSearch ~ filtered:", filtered)
        //     // setFilteredMainData(filtered);
        // }
        // else {
        //     setFilteredMainData([...studentMentorDetails]);
        // }
    }

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleClickSearchButton();
        }
    }

    const handleClickSearchButton = async () => {
        setLoading((prev) => ({ ...prev, search: true }));
        if (searchTerm) {
            // BACKEND - SEARCH
            const filtered = await getStudentMentorDetails(undefined, searchTerm, Object.keys(filterButtons).find((item) => filterButtons[item] == true));
            console.log("handleClickSearchButton ~ filtered:", filtered)
            setFilteredMainData(filtered);
        }
        else {
            setFilteredMainData([...studentMentorDetails]);
        }
        setLoading((prev) => ({ ...prev, search: false }));
    }

    const handleFilterButton = async (flag, scroll) => {
        let temp_filteredData = [...studentMentorDetails];
        const temp_filter = {};
        for (let key in filterButtons) {
            if (key === flag) temp_filter[key] = true;
            else temp_filter[key] = false;
        }
        setFilterButtons(temp_filter);
        if (flag === 'All') {
            temp_filteredData = [...studentMentorDetails];
        } else {
            temp_filteredData = await getStudentMentorDetails(undefined, searchTerm, flag)
        }
        setFilteredMainData(temp_filteredData);
        if (scroll) scrollToTargetId('StudentsList');
    }

    const confirmHandleReleaseSlot = (student) => {
        setDialogue((prev) => ({
            ...prev,
            open: true,
            actions: [
                { width: '40%', label: 'Reject', handler: () => { dialogue.onClose() }, color: Color.red, variant: "outlined" },
                { width: '40%', label: 'Accept', handler: () => { handleReleaseSlot(student) }, color: Color.green, background: Color.green_highlight, variant: "outlined" }
            ],
            title: 'Confirmation',
            content: <p style={{ textAlign: 'center', lineHeight: '25px' }}>Are you sure you want to release this slot?</p>,
        }))
    }

    const handleReleaseSlot = async (student) => {
        try {
            const post_body = {
                "order_id": student.id,
                "slot_start_time": student.slot_start_time,
                "slot_end_time": student.slot_end_time,
            }
            await service.post(`/os/superAdmin/action/releaseBooking?order_id=${student.id}`, post_body);
            const data = await getStatLists();
            handleMainEffect(data);
        } catch (error) {
            console.error("Error while releasing slot:", error);
        }
    }
    const handleBackgroundColorChange = (order_id, student_index) => {
        console.log("inside", order_id == swapped_student, swapped_student)
        if (![undefined, null].includes(swapped_student) && order_id === swapped_student) {
            let interval = setInterval(() => {
                setBackgroundColor(prevColor => (prevColor === "#E8F3EA" ? student_index % 2 ? 'rgba(251, 251, 251, 1)' : Color.white : "#E8F3EA"));
            }, 800)

            setTimeout(() => {
                clearInterval(interval)
                setSwappedStudent(0);
            }, 5000)
        }
        // Example toggle between two colors
    };

    const handleSwap = async (student) => {
        try {
            const res = await service.get(`/os/superadmin/swap/getStudents?process_id=${process_id}&spe_id=${student.spe_id}&order_id=${student.id}`)
            console.log("result of handleswap:", res);
            setSwappingStudents((prev) => ({
                ...prev,
                studentList: res.data,
                filter_student_list: res.data
            }))
        } catch (error) {
            console.error("Error while releasing slot:", error);
        }
    }
    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ ...DivCenter, width: '100%', mb: '16px' }}>
                <TextField
                    name="search_colleges"
                    // label="Search Student"
                    placeholder='Search Students'
                    variant="outlined"
                    value={searchTerm}
                    onChange={handleChangeSearch}
                    onKeyDown={handleKeyPress}
                    fullWidth
                    sx={{ width: '80%', borderRadius: "12px" }}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Search sx={{ color: Color.neutralMidGrey }} />
                            </InputAdornment>
                        ),
                        sx: { borderRadius: "12px", height: "58px" }
                    }}
                />
                <Box sx={{ ...DivCenter, width: '10%' }}><Button variant='contained' onClick={() => handleClickSearchButton()} sx={{ ...styles.button_styles.contained }}>Search</Button></Box>
                <Box sx={{ width: '10%', minWidth: '121px' }}>
                    <Tooltip title="Mentor feedback" >
                        <IconButton onClick={() => generateExcel()}><SaveAltOutlined /></IconButton></Tooltip>
                    <Tooltip title="Student feedback" >
                        <IconButton onClick={() => studentFeedbackDownload()}><SaveAltOutlined /></IconButton></Tooltip>
                    <Tooltip title="Session Summary List" >
                        <IconButton onClick={() => downloadStudentMentorDetailsSummary()}><GetApp /></IconButton></Tooltip>
                    {/* <IconButton onClick={() => downloadStudentMentorFeedbackSummary()}><UploadFile /></IconButton> */}
                </Box>
            </Box>
            <Box sx={{ ...DivCenter, width: '100%', justifyContent: 'flex-start', gap: '8px', mb: '24px' }}>
                {Object.keys(filterButtons).map((filter) => <Button onClick={() => { handleFilterButton(filter, true); }} sx={{
                    ...styles.filter_buttons,
                    background: filterButtons[filter] ? 'rgba(195, 236, 245, 1)' : Color.white,
                }}>{filter !== "All" ? formatConvertermonthfirst(parseInt(filter)) : filter}</Button>)}
            </Box>
            <Box sx={{ ...DivCenter, width: '100%', justifyContent: 'space-between', background: 'rgba(195, 236, 245, 0.1)', minHeight: '113px', p: '16px' }}>
                <Typography style={{ width: '100%', textAlign: 'left', fontSize: '22px', color: Color.primary1, }}>Student details</Typography>
                <Typography style={{ width: '100%', textAlign: 'left', fontSize: '22px', color: Color.primary1, }}>Specialization</Typography>
                <Typography style={{ width: '100%', textAlign: 'left', fontSize: '22px', color: Color.primary1, }}>Date and Time</Typography>
                <Typography style={{ width: '100%', textAlign: 'left', fontSize: '22px', color: Color.primary1, }}>Mentor Details</Typography>
                <Typography style={{ width: '100%', textAlign: 'left', fontSize: '22px', color: Color.primary1, }}>OTP</Typography>

                {!markAsDone ?
                    <Typography style={{ width: '100%', textAlign: 'center', fontSize: '22px', color: Color.primary1, }}>Action</Typography> :
                    <Typography style={{ width: '100%', textAlign: 'center', fontSize: '22px', color: Color.primary1, }}>Status</Typography>

                }
            </Box>
            {loading.search ?
                [...Array(1)].fill(null).map((_, index) => (
                    <Box sx={{ ...DivCenter, width: '100%', justifyContent: 'space-between', minHeight: '113px', p: '16px', }}>
                        <Box>
                            <Skeleton variant="text" sx={{ fontSize: '1rem' }} animation="wave" width="120px" />
                            <Skeleton variant="text" sx={{ fontSize: '1rem' }} animation="wave" width="120px" />
                            <Skeleton variant="text" sx={{ fontSize: '1rem' }} animation="wave" width="120px" />
                        </Box>
                        <Box>
                            <Skeleton variant="text" sx={{ fontSize: '1rem' }} animation="wave" width="120px" />
                        </Box>
                        <Box>
                            <Skeleton variant="text" sx={{ fontSize: '1rem' }} animation="wave" width="120px" />
                        </Box>
                        <Box>
                            <Skeleton variant="text" sx={{ fontSize: '1rem' }} animation="wave" width="120px" />
                            <Skeleton variant="text" sx={{ fontSize: '1rem' }} animation="wave" width="120px" />
                            <Skeleton variant="text" sx={{ fontSize: '1rem' }} animation="wave" width="120px" />
                        </Box>
                        <Box sx={{ ...DivCenter, gap: '16px' }}>
                            <Skeleton variant="text" sx={{ fontSize: '1rem' }} animation="wave" width="120px" />
                            <Skeleton variant="text" sx={{ fontSize: '1rem' }} animation="wave" width="120px" />
                        </Box>
                        <Box sx={{ ...DivCenter, gap: '16px' }}>
                            <Skeleton variant="text" sx={{ fontSize: '1rem' }} animation="wave" width="120px" />
                            <Skeleton variant="text" sx={{ fontSize: '1rem' }} animation="wave" width="120px" />
                        </Box>
                    </Box>
                ))
                : <Box onScroll={handleScroll} sx={{ width: '100%', height: '500px', overflowY: 'auto', }}>
                    {filteredMainData.length === 0 && !loading.lazyLoading && (
                        <p>No data available.</p>
                    )}

                    {filteredMainData.map((student, student_index) =>
                        <Box sx={{ ...DivCenter, width: '100%', background: student_index % 2 ? 'rgba(251, 251, 251, 1)' : Color.white, minHeight: '113px', p: '16px' }}>
                            <Box
                                // onClick={() => handleBackgroundColorChange(student_index)}
                                sx={{
                                    ...DivCenter, backgroundColor, flexDirection: 'column', width: '100%', justifyContent: 'flex-start',
                                    transition: 'background-color 100ms linear'
                                }}>
                                <Typography style={{
                                    width: '100%', color: Color.primary1, fontSize: '16px',
                                }}>{student.student_name}</Typography>
                                <Typography style={{ width: '100%', color: Color.neutralMidGrey, fontSize: '16px' }}>{student.student_email}</Typography>
                                <Typography style={{ width: '100%', color: Color.neutralMidGrey, fontSize: '16px' }}>{student.student_ph_no}</Typography>
                            </Box>
                            <Box sx={{ ...DivCenter, width: '100%', }}>
                                <Typography style={{ textAlign: 'center', width: '100%', color: Color.neutralMidGrey, fontSize: '16px', flexWrap: 'wrap' }}>{student.spe_name ?? "-"}</Typography>
                            </Box>
                            <Box sx={{ ...DivCenter, width: '100%', }}>
                                <Typography style={{ textAlign: 'center', width: '100%', color: Color.neutralMidGrey, fontSize: '16px' }}>
                                    {student?.start_time ? formatConvertermonthfirst(student.start_time, true, true, true) : "-"}
                                </Typography>
                            </Box>
                            <Box sx={{ ...DivCenter, flexDirection: 'column', width: '100%', }}>
                                <Typography style={{ width: '100%', textAlign: 'left', color: Color.primary1, fontSize: '16px' }}>{student.mentor_name ?? '-'}</Typography>
                                <Typography style={{ width: '100%', textAlign: 'left', color: Color.neutralMidGrey, fontSize: '16px' }}>{student.mentor_email ?? '-'}</Typography>
                                <Typography style={{ width: '100%', textAlign: 'left', alignSelf: "center", color: Color.neutralMidGrey, fontSize: '16px' }}>{student.mentor_ph_no ?? '-'}</Typography>
                            </Box>
                            <Box sx={{ ...DivCenter, flexDirection: 'column', width: '100%', }}>
                                <Typography style={{ width: '', textAlign: 'center', color: Color.neutralMidGrey, fontSize: '16px' }}>{student?.otp ?? '-'}</Typography>
                            </Box>
                            {!markAsDone ? <Box sx={{ ...DivCenter, width: '230px', gap: '12px', }}>
                                {!student.start_time ?
                                    <Button
                                        disabled={student.loading}
                                        variant='outlined'
                                        onClick={() => {
                                            setChooseMentor((prev) => ({
                                                ...prev,
                                                open: true,
                                                mentorSlotDetails: null,
                                                mentorData: mentorStatList,
                                                extraData: { heading: `Schedule session for ${student.student_name}`, order_id: student.id, student_name: student.student_name },
                                            }))
                                        }}
                                        style={{ ...styles.button_styles.outlined, width: "150px", color: Color.primary1, fontSize: '16px', textTransform: 'none' }}>
                                        Schedule now
                                    </Button> : <Box sx={{ ...DivCenter, width: '230px', gap: '12px', }}>
                                        <Button
                                            disabled={student.loading}
                                            variant='outlined'
                                            onClick={() => {
                                                setChooseMentor((prev) => ({
                                                    ...prev,
                                                    open: true,
                                                    mentorSlotDetails: student,
                                                    mentorData: mentorStatList,
                                                    extraData: { heading: `Change session for ${student.student_name}`, order_id: student.id, student_name: student.student_name },
                                                }))
                                            }}
                                            style={{ ...styles.button_styles.outlined, minWidth: "30px", color: Color.primary1, padding: "2px 18px", fontSize: '14px', textTransform: 'none' }}>
                                            Edit
                                        </Button>
                                        <div style={{ background: Color.neutralMidGrey, height: '50px', width: '1px' }} />
                                        <Button
                                            disabled={student.loading}
                                            variant='outlined'
                                            onClick={() => { handleReleaseSlot(student) }}
                                            style={{ ...styles.button_styles.outlined, minWidth: "30px", padding: "2px 18px", color: Color.primary1, fontSize: '14px', textTransform: 'none' }}>
                                            Release
                                        </Button>
                                        {
                                            student?.order_flag === "Scheduled" ?
                                                <>
                                                    <div style={{ background: Color.neutralMidGrey, height: '50px', width: '1px' }} />

                                                    <Button
                                                        disabled={student.loading}
                                                        variant='outlined'
                                                        onClick={() => {
                                                            handleSwap(student);
                                                            setSwappingStudents((prev) => ({
                                                                ...prev,
                                                                open: true,
                                                                swappedRequestDetails: student,
                                                                studentDetails: mentorStatList,
                                                                extraData: { heading: `Swap Student with ${student.student_name}`, order_id: student.id, student_name: student.student_name },
                                                            }))
                                                        }}
                                                        style={{ ...styles.button_styles.outlined, minWidth: "30px", color: Color.primary1, padding: "2px 18px", fontSize: '14px', textTransform: 'none' }}>
                                                        Swap
                                                    </Button></> : null}
                                    </Box>}
                            </Box> :
                                <Box sx={{ ...DivCenter, flexDirection: 'column', width: '100%', }}>
                                    <Typography style={{ width: '', textAlign: 'center', color: Color.neutralMidGrey, fontSize: '16px' }}>
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                padding: 8,
                                            }}
                                        >
                                            <a
                                                // href={params.row.}
                                                target="_blank"
                                                rel="noreferrer"
                                            // style={{ textDecoration: "underline" }}
                                            >
                                                {getStatus(
                                                    student.order_flag, student.is_absent, student.student_availability
                                                )}
                                            </a>
                                        </div>
                                    </Typography>
                                </Box>
                            }
                        </Box>
                    )}

                    {loading.lazyLoading &&
                        [...Array(1)].fill(null).map((_, index) => (
                            <Box sx={{ ...DivCenter, width: '100%', justifyContent: 'space-between', minHeight: '113px', p: '16px', }}>
                                <Box>
                                    <Skeleton variant="text" sx={{ fontSize: '1rem' }} animation="wave" width="120px" />
                                    <Skeleton variant="text" sx={{ fontSize: '1rem' }} animation="wave" width="120px" />
                                    <Skeleton variant="text" sx={{ fontSize: '1rem' }} animation="wave" width="120px" />
                                </Box>
                                <Box>
                                    <Skeleton variant="text" sx={{ fontSize: '1rem' }} animation="wave" width="120px" />
                                </Box>
                                <Box>
                                    <Skeleton variant="text" sx={{ fontSize: '1rem' }} animation="wave" width="120px" />
                                </Box>
                                <Box>
                                    <Skeleton variant="text" sx={{ fontSize: '1rem' }} animation="wave" width="120px" />
                                    <Skeleton variant="text" sx={{ fontSize: '1rem' }} animation="wave" width="120px" />
                                    <Skeleton variant="text" sx={{ fontSize: '1rem' }} animation="wave" width="120px" />
                                </Box>
                                <Box sx={{ ...DivCenter, gap: '16px' }}>
                                    <Skeleton variant="text" sx={{ fontSize: '1rem' }} animation="wave" width="120px" />
                                    <Skeleton variant="text" sx={{ fontSize: '1rem' }} animation="wave" width="120px" />
                                </Box>
                            </Box>
                        ))
                    }
                </Box>}
        </Box>
    )
} 